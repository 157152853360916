import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  AppNameText,
  BackDrop,
  BaseButton,
  FullScreenBox,
  PageBlur,
} from 'components';
import { ACCESS_STATUS } from 'utils';
import { useStyles } from './styles';
import { UserInfo } from './components/user-info';
import { VerifyCode } from './components/verify-code';
import {
  logoutUser,
  selectAccessStatus,
  selectIsLogging,
  selectRegisterData,
} from 'app-redux';

export const SignUp: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isLogging = useAppSelector(selectIsLogging);
  const registerData = useAppSelector(selectRegisterData);
  const accessStatus = useAppSelector(selectAccessStatus);

  const _handleLogout = async () => {
    await dispatch(logoutUser());
  };
  return (
    <FullScreenBox
      flexDirection={'column'}
      display={'flex'}
      position={'relative'}
      justifyContent={'center'}>
      {accessStatus === null && !registerData && <UserInfo />}
      {accessStatus === ACCESS_STATUS.CONFIRM_SIGN_UP && registerData && (
        <VerifyCode />
      )}
      <Box className={classes.appNameTextView}>
        <AppNameText />
      </Box>
      {accessStatus === ACCESS_STATUS.CONFIRM_SIGN_UP && registerData && (
        <Box className={classes.headerBtn}>
          <BaseButton onClick={_handleLogout}>
            Already have an account?
          </BaseButton>
        </Box>
      )}
      <BackDrop open={isLogging} />
      <PageBlur />
    </FullScreenBox>
  );
};
