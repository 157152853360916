import { Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useStyles } from './styles';
import { DropDown, TextInput } from 'components';
import { GENDER, LANGUAGES, USER_ROLE } from 'utils';
import { OnBoardingHeader } from '../onboarding-header';
import { Location } from 'lib';
import { useAppDispatch, useAppSelector } from 'hooks';
import { userOnBoarding } from 'app-redux';
import { RootState } from 'app-redux/store';

export const OnBoardingBody = () => {
  const classes = useStyles();
  const [username, setUsername] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [displayPicture, setDisplayPicture] = useState<string | null>(null);

  const { user } = useAppSelector((state: RootState) => state.authSlice);
  const dispatch = useAppDispatch();
  const location = new Location();

  const isSaveDisabled = useMemo(() => {
    return (
      !username || !gender || !country || !language || !role || !displayPicture
    );
  }, [username, gender, country, language, role, displayPicture]);

  const onSaveChanges = async () => {
    const input = {
      fullName: user.name,
      username: username,
      gender: gender.toLowerCase(),
      country: country.toLowerCase(),
      language: language.toLowerCase(),
      role: role.toLowerCase(),
      state: state.toLowerCase() || 'n/a',
      displayPicture: displayPicture,
      onboardingStatus: true,
    };
    dispatch(userOnBoarding(input));
  };
  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      zIndex={1}>
      <Grid item xs={12}>
        <OnBoardingHeader
          onSaveChanges={onSaveChanges}
          setDisplayPicture={setDisplayPicture}
          displayPicture={displayPicture}
          isSaveDisabled={isSaveDisabled}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Username
        </Typography>
        <TextInput
          type="text"
          name="username"
          value={username}
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Role
        </Typography>
        <DropDown
          list={USER_ROLE}
          placeholder={'Select'}
          setValue={setRole}
          value={role}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Gender
        </Typography>
        <DropDown
          list={GENDER}
          placeholder={'Select'}
          setValue={setGender}
          value={gender}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Country
        </Typography>
        <DropDown
          placeholder="Country"
          value={country}
          setValue={setCountry}
          reSetValue={setState}
          list={location.getCountries()}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          Language
        </Typography>
        <DropDown
          list={LANGUAGES}
          placeholder={'Select'}
          setValue={setLanguage}
          value={language}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Typography variant="body1" className={classes.labelStyle}>
          State
        </Typography>
        <DropDown
          placeholder="State"
          value={state}
          setValue={setState}
          list={location.getStates(country)}
        />
      </Grid>
    </Grid>
  );
};
