import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import {
  AgeChart,
  CustomLinearChart,
  GenderChart,
  GrowthChart,
  InfoCard,
} from 'components';
import { useStyles } from './styles';
import {
  followers1x,
  followers2x,
  followers3x,
  platform1x,
  platform2x,
  platform3x,
  views1x,
  views2x,
  views3x,
} from 'assets';
import {
  FacebookProfileCard,
  InstagramProfileCard,
  TikTokProfileCard,
  TwitchProfileCard,
  TwitterProfileCard,
  YoutubeProfileCard,
} from '../social-profile-cards';
import { formatViews } from 'utils';

interface Props {
  platformName?: string;
  data?: any;
}
export const SocialProfileDashboard: FC<Props> = ({ platformName, data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[followers1x, followers2x, followers3x]}
                  heading={
                    platformName === 'Youtube' ? 'Subscribers' : 'Followers'
                  }
                  count={
                    formatViews(data?.subscribers) ||
                    formatViews(data?.followers)
                  }
                  percentage={data?.weeklyGrowth?.subscribers?.percentage}
                  duration={`+${formatViews(
                    data?.weeklyGrowth?.subscribers?.number
                  )} this week`}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[views1x, views2x, views3x]}
                  heading="Views"
                  count={formatViews(data?.views)}
                  percentage={data?.weeklyGrowth?.views?.percentage}
                  duration={`+${formatViews(
                    data?.weeklyGrowth?.views?.number
                  )} this week`}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.gridItem}>
                <InfoCard
                  image={[platform1x, platform2x, platform3x]}
                  heading="Shares"
                  count={formatViews(data?.shares)}
                  percentage={data?.weeklyGrowth?.shares?.percentage}
                  duration={`+${formatViews(
                    data?.weeklyGrowth?.shares?.number
                  )} this week`}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <GrowthChart data={data?.monthlyGrowth} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {platformName === 'Facebook' && <FacebookProfileCard />}
          {platformName === 'Instagram' && <InstagramProfileCard />}
          {platformName === 'TikTok' && <TikTokProfileCard />}
          {platformName === 'X' && <TwitterProfileCard />}
          {platformName === 'Twitch' && <TwitchProfileCard data={data} />}
          {platformName === 'Youtube' && <YoutubeProfileCard data={data} />}
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} md={4}>
          <GenderChart data={data?.demographicData?.gender} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AgeChart data={data?.demographicData?.ageRange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" height="100%">
            <CustomLinearChart
              data={data?.demographicData?.location}
              totalCount={data?.views}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
