import { RootState } from 'app-redux/store';
import { AlertSnack } from 'components';
import { useAppSelector, withAuthenticator } from 'hooks';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  CreatorDashboard,
  CreatorProfile,
  Home,
  OnBoarding,
  PriceCalculator,
  SignIn,
  SignUp,
} from 'screens';

const ProtectedRoutes = () => {
  const { user } = useAppSelector((state: RootState) => state.authSlice);

  return (
    <Routes>
      <Route path="/*" element={<OnBoarding />} />
      {user?.onboardingStatus && (
        <>
          <Route path="/creator-dashboard" element={<CreatorDashboard />} />
        </>
      )}
      <Route path="/home" element={<Home />} />
      <Route path="/creator-profile" element={<CreatorProfile />} />
      <Route path="/price-calculator" element={<PriceCalculator />} />
    </Routes>
  );
};

const AuthenticationRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/creator-profile" element={<CreatorProfile />} />
      <Route path="/price-calculator" element={<PriceCalculator />} />
    </Routes>
  );
};

const PagesWithAuth = withAuthenticator(ProtectedRoutes, AuthenticationRoutes);

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PagesWithAuth />} />
      </Routes>
      <AlertSnack />
    </BrowserRouter>
  );
};
