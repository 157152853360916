import React, { FC } from 'react';
import { Box } from '@mui/material';
import { PageBlur } from 'components/page-blur';
import { applogo3x } from 'assets';

export const AppLoader: FC = () => {
  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.logo} />
      <PageBlur />
    </Box>
  );
};

const styles = {
  logo: {
    width: '50%',
    height: '150px',
    backgroundSize: '100% 100%',
    backgroundImage: `url(${applogo3x})`,
    zIndex: 99,
  },
  mainContainer: {
    position: 'relative',
    height: '100vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    gap: 2,
    background: 'transparent',
  },
};
