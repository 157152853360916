import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Downicon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import { Colors } from 'global-styles';

interface Props {
  list: string[];
  placeholder: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  loading?: boolean;
  reSetValue?: React.Dispatch<React.SetStateAction<string>>;
}

const ITEM_HEIGHT = 55;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      backgroundColor: Colors.primary,
      border: `1px solid ${Colors.white}`,
      marginTop: 5,
      borderRadius: '5px',
    },
  },
};

function getStyles(name: string, value: string, theme: Theme) {
  return {
    fontWeight:
      value?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,

    color: 'white',
    borderBottom: '0.5px solid #FFFFFF33',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 5,
    '&hover': {
      backgroundColor: 'red',
    },
  };
}

export const DropDown: React.FC<Props> = ({
  list,
  placeholder,
  value,
  setValue,
  loading,
  reSetValue,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const { target } = event;
    setValue(target.value);
    if (reSetValue) reSetValue('');
  };

  return (
    <div>
      <FormControl className={classes.containerStyles}>
        <Select
          defaultValue=""
          displayEmpty
          className={classes.selectStyle}
          value={value}
          onChange={handleChange}
          sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.white,
              borderWidth: '1px',
            },
          }}
          input={<OutlinedInput />}
          IconComponent={() => (
            <Downicon style={{ color: 'white', marginRight: 5 }} />
          )}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography color={'gray'}>{placeholder}</Typography>;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}>
          {loading ? (
            <MenuItem disabled className={classes.menuItemStyle}>
              Loading...
            </MenuItem>
          ) : isEmpty(list) ? (
            <MenuItem disabled className={classes.menuItemStyle}>
              No Record Found
            </MenuItem>
          ) : (
            list?.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, value, theme)}>
                {name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  );
};
