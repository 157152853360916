import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Colors } from 'global-styles';
import { FC } from 'react';

interface ButtonTypes extends ButtonProps {
  secondary?: boolean;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
  buttonColor?: string;
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  textColor?: string;
  hoverEffect?: boolean;
}

export const BaseButton: FC<ButtonTypes> = ({
  children,
  secondary = false,
  fontSize,
  height,
  width,
  buttonColor = Colors.primary,
  disabled = false,
  isLoading = false,
  textColor,
  startIcon,
  hoverEffect = true,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      disabled={disabled}
      sx={[
        {
          borderRadius: '8px',
          height: height || 48,
          color: disabled
            ? Colors.white
            : secondary
            ? buttonColor
            : textColor
            ? textColor
            : Colors.white,
          border: secondary ? `1px solid ${Colors.white}` : 0,
          fontFamily: 'Plus Jakarta Sans, sans-serif',
          width: width || 220,
          background: disabled
            ? 'transparent'
            : secondary
            ? 'transparent'
            : buttonColor,
          fontSize: fontSize ? fontSize : 15,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: secondary
              ? 'none'
              : hoverEffect
              ? Colors.primary
              : 'none', // Background color on hover
          },
          '&:disabled': {
            color: Colors.lightGrey,
          },
        },
      ]}
      startIcon={
        isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : startIcon ? (
          startIcon
        ) : null
      }>
      {children}
    </Button>
  );
};
