export const BASE_URL = 'https://master.dihle0ut9msxw.amplifyapp.com';

export const urlConstants = {
  register: '/api/user/register',
  login: '/api/user/login',
  logout: '/api/user/logout',
  getUser: '/api/user/get-user',
  updateUser: '/api/user/update',
  verify: '/api/user/verify',
  resend: '/api/user/resend',
  getUserToken: '/api/user/get-token',
  socialMeta: '/api/user/meta-data',
};

export const youtubeURLs = {
  login: '/api/youtube/login',
  insights: '/api/youtube/insights',
  getToken: '/api/youtube/get-token',
  disconnect: '/api/youtube/disconnect',
};

export const twitchURLs = {
  login: '/api/twitch/login',
  insights: '/api/twitch/insights',
  getToken: '/api/twitch/get-token',
  disconnect: '/api/twitch/disconnect',
};

export const marketplaceURLs = {
  marketplace: '/api/marketplace',
  marketplaceProfile: '/api/marketplace/user',
};
