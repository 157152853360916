import { Box } from '@mui/material';
import React from 'react';

import { useProfileCardStyles } from './styles';
import { EmptyContainer } from 'components/app-charts/empty-container';

export const EmptyProfileCard = () => {
  const classes = useProfileCardStyles();
  return (
    <Box className={classes.mainContainer}>
      <EmptyContainer />
    </Box>
  );
};
