import { Country, State, City } from 'country-state-city';

export class Location {
  getCountries() {
    const countries = Country.getAllCountries();
    return countries.map((item) => item.name);
  }

  getCountryCodeByName(countryName: string): string | undefined {
    const countries = Country.getAllCountries();
    const country = countries.find((item) => item.name === countryName);
    return country ? country.isoCode : undefined;
  }

  getStates(countryInput: string) {
    let countryCode: string | undefined;

    if (
      Country.getAllCountries().some((item) => item.isoCode === countryInput)
    ) {
      countryCode = countryInput;
    } else {
      countryCode = this.getCountryCodeByName(countryInput);
    }

    if (countryCode) {
      const states = State.getStatesOfCountry(countryCode);
      return states.map((item) => item.name);
    } else {
      return [];
    }
  }

  getCities(countryCode: string, stateCode: string) {
    const cities = City.getCitiesOfState(countryCode, stateCode);
    return cities.map((item) => ({ label: item.name, value: item.name }));
  }

  getStateByCode(stateCode: string) {
    const state = State.getStateByCode(stateCode);
    return state ? state.name : stateCode;
  }

  getCountryByCode(countryCode: string) {
    const country = Country.getCountryByCode(countryCode);
    return country ? country.name : countryCode;
  }
}
