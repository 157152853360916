import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useInsightsChartStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: Colors.primaryGlass,
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    color: Colors.white,
  },
  ageHeading: {
    flexGrow: 1,
    textAlign: 'left',
  },
  labelRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'center',
    paddingLeft: 25,
  },
  labelView: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  labelRightBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 55,
  },
  smallCircle: {
    width: 10,
    height: 10,
    marginRight: 4,
    borderRadius: '50%',
  },
  customTooltip: {
    background: 'red',
  },
}));

export const useGenderChartStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: Colors.primaryGlass,
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    color: Colors.white,
  },
  labelRow: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 2,
    alignItems: 'center',
  },
  labelView: {
    display: 'flex',
    alignItems: 'center',
  },
  smallCircle: {
    width: 10,
    height: 10,
    marginRight: 4,
    borderRadius: '50%',
  },
}));

export const useLinearChartStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: Colors.primaryGlass,
    borderRadius: '10px',
    padding: '20px',
    color: Colors.white,
    height: 265,
    overflow: 'auto',
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 3.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px',
  },
  barContainer: {
    width: '100%',
    flex: 1,
    marginRight: 20,
    flexDirection: 'column',
    display: 'flex',

    gap: 4,
  },
}));
