import { generateClient } from 'aws-amplify/api';

interface GraphQLError {
  message: string;
  locations?: Array<{ line: number; column: number }>;
  path?: Array<string | number>;
}

interface GraphQLResponse<TData> {
  data?: TData;
  errors?: GraphQLError[];
}

export const client = generateClient();

export const executeGraphqlCall = async <TData>(
  query: string,
  variables: Record<string, any>
): Promise<GraphQLResponse<TData>> => {
  try {
    const response = await client.graphql({
      query,
      variables,
    });
    if (!response || typeof response !== 'object') {
      throw new Error('Invalid response structure');
    }

    return response as GraphQLResponse<TData>;
  } catch (error) {
    console.error('Error executing GraphQL call:', error);
    throw new Error(
      `GraphQL call failed: ${
        error instanceof Error ? error.message : 'Unknown error'
      }`
    );
  }
};
