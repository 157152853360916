import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { logoutUnAuthenticatedUser } from 'app-redux';
import { AppLoader } from 'components';

export const withAuthenticator = (ProtectedRoutes, AuthRoutes) => (props) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { isLogin } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();

  const checkAuthState = async () => {
    try {
      // await getCurrentUser();
      if (isLogin) setAuthenticated(true);
      else setAuthenticated(false);
    } catch (err) {
      if (isLogin) dispatch(logoutUnAuthenticatedUser());
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    checkAuthState();
  });
  if (loading) return <AppLoader />;

  if (authenticated) return <ProtectedRoutes {...props} />;

  return <AuthRoutes {...props} />;
};
