import { Box, Typography } from '@mui/material';
import { Colors } from 'global-styles';
import React from 'react';

export const EmptyContainer = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%">
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        }}>
        Insufficient data available to generate insights
      </Typography>
    </Box>
  );
};
