import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useLinearChartStyles } from './styles';
import { Colors } from 'global-styles';
import { formatViews } from 'utils';
import { isEmpty } from 'lodash';
import { EmptyContainer } from './empty-container';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json'; // Import the desired language JSON

countries.registerLocale(enLocale);
interface CountryData {
  country: string;
  views: number;
  viewerPercentage: string | number;
}

const dummydata: CountryData[] = [
  { country: 'PK', views: 80, viewerPercentage: '77.26' },
  { country: 'IN', views: 60, viewerPercentage: '55.26' },
  { country: 'CH', views: 70, viewerPercentage: '23.26' },
];

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary})`,
  },
  backgroundColor: '#333',
}));

interface Props {
  data?: any;
  totalCount?: any;
}
// TODO: Remove dummydata
export const CustomLinearChart: React.FC<Props> = ({
  data = dummydata,
  totalCount,
}) => {
  const classes = useLinearChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Typography variant="h6">Country/Region</Typography>
      {isEmpty(data) ? (
        <EmptyContainer />
      ) : (
        <>
          {data.map((item, index) => (
            <Box key={index} className={classes.innerContainer}>
              <Box className={classes.barContainer}>
                <Typography variant="body2">
                  {countries.getName(item.country, 'en')}
                </Typography>
                <CustomLinearProgress
                  variant="determinate"
                  value={(item.views / totalCount) * 100}
                />
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'right' }}>
                {`${formatViews(item.views)}`}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
