import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FullScreenBox, PageBlur } from 'components';
import { HomeHeader, ProfileCard } from './components';
import { useStyles } from './styles';
import { useAppDispatch } from 'hooks';
import { getMarketPlaceData } from 'app-redux';

export const Home = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMarketPlaceData());
  }, [dispatch]);

  return (
    <FullScreenBox justifyContent={'center'} flexDirection={'column'}>
      <HomeHeader />
      <Box className={classes.mainContainer}>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Top Rated Content Creators</Typography>
          </Grid>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ProfileCard />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h6">Best Podcasters</Typography>
          </Grid>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ProfileCard />
            </Grid>
          ))}
        </Grid>
      </Box>
      <PageBlur />
    </FullScreenBox>
  );
};
