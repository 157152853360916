import { Box, Typography } from '@mui/material';
import { Colors } from 'global-styles';
import React, { FC } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { TooltipProps } from 'recharts';
import { useInsightsChartStyles } from './styles';
import { isEmpty } from 'lodash';
import { EmptyContainer } from './empty-container';

const formatYAxisTick = (tick: number): string => {
  if (tick >= 1000) {
    return `${(tick / 1000).toFixed(0)}k`;
  }
  return `${tick}`;
};

const dummydata = [
  { ageGroup: '12-18', viewerPercentage: 10 },
  { ageGroup: '19-27', viewerPercentage: 27 },
  { ageGroup: '28-32', viewerPercentage: 32 },
  { ageGroup: '33-42', viewerPercentage: 43 },
  { ageGroup: '43-56', viewerPercentage: 45 },
];

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
  payload,
  label,
}) => {
  if (!payload || !payload.length) return null;

  return (
    <Box
      className="custom-tooltip"
      style={{
        backgroundColor: Colors.primary,
        border: '0px',
        borderRadius: '8px',
        padding: '10px',
        color: Colors.white,
      }}>
      <Typography>{`${label}`}</Typography>
      <Typography>{`${payload[0].value}%`}</Typography>
    </Box>
  );
};

interface Props {
  data?: any;
}
//TODO: Remove dummydata
export const AgeChart: FC<Props> = ({ data = dummydata }) => {
  const classes = useInsightsChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Typography
        variant="h6"
        className={classes.ageHeading}
        sx={{ marginBottom: 2 }}>
        Age
      </Typography>
      <ResponsiveContainer width="100%" height={233}>
        {isEmpty(data) ? (
          <EmptyContainer />
        ) : (
          <BarChart data={data}>
            <CartesianGrid
              stroke={Colors.darkGrey}
              strokeDasharray="3 3"
              vertical={false}
            />
            <XAxis
              dataKey="ageGroup"
              stroke="#8884d8"
              tick={{ fill: '#8884d8' }}
              tickLine={{ stroke: '#8884d8' }}
              axisLine={{ stroke: '#8884d8' }}
            />
            <YAxis
              stroke="#8884d8"
              tick={{ fill: '#8884d8' }}
              tickLine={{ stroke: '#8884d8' }}
              axisLine={{ stroke: '#8884d8' }}
              tickFormatter={formatYAxisTick}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="viewerPercentage"
              fill={Colors.gradientSecondary}
              barSize={7}
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
