import React, { FC, useEffect, useReducer } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  AppNameText,
  BackDrop,
  BaseButton,
  FullScreenBox,
  GlassBox,
  SocialButton,
  PageBlur,
  TextInput,
} from 'components';
import { Colors } from 'global-styles';
import {
  loginUser,
  registerUser,
  selectAccessStatus,
  selectIsLogging,
  showAlert,
} from 'app-redux';
import {
  ACCESS_STATUS,
  ALERT_TYPES,
  validateEmail,
  validateLoginForm,
  validatePassword,
} from 'utils';
import { useStyles } from './styles';
import { google3x } from 'assets';
import { loginFormType } from 'types';
import { isEmpty } from 'lodash';

export const SignIn: FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const isLogging = useAppSelector(selectIsLogging);
  const accessStatus = useAppSelector(selectAccessStatus);

  const [{ email, emailError, password, passwordError, isValid }, setData] =
    useReducer(
      (state: loginFormType, newState) => ({ ...state, ...newState }),
      {
        email: '',
        password: '',
        isValid: false,
        emailError: false,
        passwordError: false,
      }
    );
  const _handleChange = ({ target: { value, name } }) =>
    setData({ [name]: value });

  const emailValidation = () => {
    if (validateEmail(email)) setData({ emailError: false });
    else setData({ emailError: true });
  };

  const passwordValidation = () => {
    if (validatePassword(password)) setData({ passwordError: false });
    else setData({ passwordError: true });
  };

  useEffect(() => {
    if (validateLoginForm({ email, password })) setData({ isValid: true });
    else setData({ isValid: false });
  }, [email, password, dispatch]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form submission
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      if (accessStatus === ACCESS_STATUS.DONE) navigation('/creator-dashboard');
    } catch (error) {
      dispatch(
        showAlert({
          isAlertVisible: true,
          alertType: ALERT_TYPES.ERROR,
          alertMessage: error,
        })
      );
    }
  };

  useEffect(() => {
    if (
      accessStatus === ACCESS_STATUS.CONFIRM_SIGN_UP &&
      !isEmpty(registerUser)
    ) {
      navigation('/sign-up');
    }
  }, [accessStatus, registerUser]);
  return (
    <form onSubmit={handleLogin}>
      <FullScreenBox
        flexDirection={'column'}
        display={'flex'}
        position={'relative'}
        justifyContent={'center'}>
        <GlassBox className={classes.middleBox}>
          <Box className={classes.headerText}>
            <Typography className={classes.text}>Welcome Back!</Typography>
            <Typography className={classes.description}>
              Unlock Your Creative Potential with Our Tools
            </Typography>
          </Box>
          <Box className={classes.columnView}>
            <Typography>Email</Typography>
            <TextInput
              type="text"
              name="email"
              value={email}
              error={emailError}
              className={classes.inputStyles}
              placeholder="Email"
              onChange={_handleChange}
              onBlur={emailValidation}
            />
          </Box>
          <Box className={classes.columnView}>
            <Typography>Password</Typography>
            <TextInput
              name="password"
              error={passwordError}
              value={password}
              className={classes.inputStyles}
              placeholder="Password"
              type="password"
              onChange={_handleChange}
              onBlur={passwordValidation}
            />
          </Box>
          <BaseButton
            disabled={!isValid}
            type="submit" // Ensure the button triggers form submission
            className={classes.button}
            style={{
              background: isValid
                ? `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`
                : Colors.lightGlass,
              color: isValid ? 'white' : Colors.lightGlass,
            }}>
            Sign In
          </BaseButton>
          <Box className={classes.orView}>
            <Box className={classes.orLine} />
            <Typography className={classes.orText}>OR</Typography>
            <Box className={classes.orLine} />
          </Box>
          <SocialButton logo={google3x} heading="Continue with Google" />
          <Typography
            color={'white'}
            variant="body2"
            className={classes.resendText}>
            {`Don't have an Account?`}{' '}
            <span
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => navigation('/sign-up')}>
              Sign Up
            </span>
          </Typography>
        </GlassBox>
        <Box className={classes.appNameTextView}>
          <AppNameText />
        </Box>
        <BackDrop open={isLogging} />
        <PageBlur />
      </FullScreenBox>
    </form>
  );
};
