import {
  AppLoader,
  AppNameText,
  BackDrop,
  BaseButton,
  FullScreenBox,
  PageBlur,
} from 'components';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { OnBoardingBody } from './components';
import { RootState } from 'app-redux/store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'app-redux';
import { isEmpty } from 'lodash';

export const OnBoarding = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user, isLogging } = useAppSelector(
    (state: RootState) => state.authSlice
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.onboardingStatus) navigate('/creator-dashboard');
  }, [user]);

  const _handleLogout = async () => {
    await dispatch(logoutUser());
  };

  return (
    <>
      {!isEmpty(user) && !user?.onboardingStatus ? (
        <FullScreenBox justifyContent={'center'}>
          <Box className={classes.innerContainer}>
            <OnBoardingBody />
          </Box>
          <Box className={classes.appNameTextView}>
            <AppNameText />
          </Box>
          <Box className={classes.headerBtn}>
            <BaseButton onClick={_handleLogout}>
              Already have an account?
            </BaseButton>
          </Box>
          <PageBlur />
        </FullScreenBox>
      ) : (
        <AppLoader />
      )}
      <BackDrop open={isLogging} />
    </>
  );
};
