import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    width: '100%',
    paddingBottom: 50,
  },
  innerLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
  },
  columnView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameText: {},
  emailText: {
    color: `${Colors.lightWhite} !important`,
  },
  saveBtn: {
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
  },
}));
