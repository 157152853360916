import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';
import { apiService } from 'services';
import { youtubeURLs } from 'utils';
import { getSocialMeta } from './social-links-slice';

type initialStateType = {
  youtubeLoading: boolean;
  youtubeError: string | null;
  youtubeMeta: any | null;
  youtubeInsights: any | null;
};

type YoutubeInsightsResponse = {
  youtubeInsightsData: any;
};

const initialState: initialStateType = {
  youtubeLoading: false,
  youtubeError: null,
  youtubeMeta: null,
  youtubeInsights: null,
};

export const getYoutubeInsights = createAsyncThunk<
  YoutubeInsightsResponse,
  void,
  { state: RootState }
>('youtubeSlice/getYoutubeInsights', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const youtubeMeta = state.youtubeSlice.youtubeMeta;

  try {
    const resp: any = await apiService.getResource(
      `${youtubeURLs.insights}?refreshToken=${youtubeMeta?.refresh_token}`
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getYoutubeTokens = createAsyncThunk(
  'youtubeSlice/getYoutubeTokens',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(youtubeURLs.getToken);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const disconnectYoutube = createAsyncThunk(
  'youtubeSlice/disconnectYoutube',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(youtubeURLs.disconnect);
      thunkAPI.dispatch(getSocialMeta());
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const youtubeSlice = createSlice({
  name: 'youtubeSlice',
  initialState,
  reducers: {
    setYoutubeMeta: (state, action) => {
      state.youtubeMeta = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getYoutubeInsights.pending, (state) => {
        state.youtubeLoading = true;
        state.youtubeError = null;
      })
      .addCase(getYoutubeInsights.fulfilled, (state, action) => {
        state.youtubeLoading = false;
        state.youtubeInsights = action.payload?.youtubeInsightsData;
      })
      .addCase(getYoutubeInsights.rejected, (state, action) => {
        state.youtubeLoading = false;
        state.youtubeError = action.payload as string;
      })
      .addCase(getYoutubeTokens.pending, (state) => {
        state.youtubeLoading = true;
        state.youtubeError = null;
      })
      .addCase(getYoutubeTokens.fulfilled, (state, action) => {
        state.youtubeLoading = false;
        state.youtubeMeta = action.payload;
      })
      .addCase(getYoutubeTokens.rejected, (state, action) => {
        state.youtubeLoading = false;
        state.youtubeError = action.payload as string;
      })
      .addCase(disconnectYoutube.pending, (state) => {
        state.youtubeLoading = true;
        state.youtubeError = null;
      })
      .addCase(disconnectYoutube.fulfilled, (state) => {
        state.youtubeLoading = false;
        state.youtubeInsights = null;
        state.youtubeMeta = null;
      })
      .addCase(disconnectYoutube.rejected, (state, action) => {
        state.youtubeLoading = false;
        state.youtubeError = action.payload as string;
      });
  },
});

export const { setYoutubeMeta } = youtubeSlice.actions;

const selectYoutubeState = (state: RootState) => state.youtubeSlice;

const selectYoutubeInsights = createSelector(
  selectYoutubeState,
  (state) => state.youtubeInsights
);

const selectYoutubeMeta = createSelector(
  selectYoutubeState,
  (state) => state.youtubeMeta
);

const selectYoutubeLoading = createSelector(
  selectYoutubeState,
  (state) => state.youtubeLoading
);

export { selectYoutubeInsights, selectYoutubeMeta, selectYoutubeLoading };

export default youtubeSlice.reducer;
