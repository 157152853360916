import { Box, Typography } from '@mui/material';
import React from 'react';
import { AppNameText } from 'components/app-name-text';
import { useStyles } from './styles';
import { BaseButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import { RootState } from 'app-redux/store';

const pages = [
  { name: 'Home', to: '/home' },
  { name: 'Price Calculator', to: '/price-calculator' },
  { name: 'Login', to: '/sign-in' },
];

const protectedPages = [
  { name: 'Profile', to: '/creator-profile' },
  { name: 'Price Calculator', to: '/price-calculator' },
];

export const HomeHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isLogin } = useAppSelector((state: RootState) => state.authSlice);

  const handleNavigation = (to) => navigate(to);

  const handleCreatorLogin = () => navigate('/sign-up');
  const handleDashboard = () => navigate('/creator-dashboard');

  return (
    <Box className={classes.mainContainer}>
      <AppNameText />
      <Box className={classes.leftSide}>
        {!isLogin && (
          <>
            {pages.map((page, index) => (
              <Box
                key={index}
                className={classes.menuBtn}
                onClick={() => handleNavigation(page.to)}>
                <Typography textAlign={'center'}>{page.name}</Typography>
              </Box>
            ))}
          </>
        )}

        {isLogin ? (
          <Box className={classes.leftSide}>
            {protectedPages.map((page, index) => (
              <Box
                key={index}
                className={classes.menuBtn}
                onClick={() => handleNavigation(page.to)}>
                <Typography textAlign={'center'}>{page.name}</Typography>
              </Box>
            ))}
            <BaseButton
              className={classes.creatorBtn}
              onClick={handleDashboard}>
              <Typography>Dashboard</Typography>
            </BaseButton>
          </Box>
        ) : (
          <BaseButton
            className={classes.creatorBtn}
            onClick={handleCreatorLogin}>
            <Typography>Join as Creator</Typography>
          </BaseButton>
        )}
      </Box>
    </Box>
  );
};
