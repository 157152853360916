import React, { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { SvgIconComponent } from '@material-ui/icons';
import { Colors } from 'global-styles';

interface Props {
  title: string;
  Icon: SvgIconComponent;
  _handleOnClick?: () => void;
}

export const HeaderButton: FC<Props> = ({ Icon, title, _handleOnClick }) => {
  return (
    <Tooltip title={title}>
      <IconButton sx={styles.iconButton} onClick={_handleOnClick}>
        <Icon style={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};

const styles = {
  iconButton: {
    height: '30px',
    width: '30px',
    borderRadius: '4px',
    margin: 1,
    '&:hover': {
      cursor: 'pointer',
      background: Colors.primary,
    },
  },
};
