import {
  d_applogo1x,
  d_applogo2x,
  d_applogo3x,
  d_chat1x,
  d_chat2x,
  d_chat3x,
  d_dashboard1x,
  d_dashboard2x,
  d_dashboard3x,
  d_facebook1x,
  d_facebook2x,
  d_facebook3x,
  d_financial1x,
  d_financial2x,
  d_financial3x,
  d_instagram1x,
  d_instagram2x,
  d_instagram3x,
  d_link1x,
  d_link2x,
  d_link3x,
  d_settings1x,
  d_settings2x,
  d_settings3x,
  d_tiktok1x,
  d_tiktok2x,
  d_tiktok3x,
  d_twitch1x,
  d_twitch2x,
  d_twitch3x,
  d_x1x,
  d_x2x,
  d_x3x,
  d_youtube1x,
  d_youtube2x,
  d_youtube3x,
} from 'assets';
import { jwtDecode } from 'jwt-decode';

export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const genderData = [
  { gender: 'Male', viewerPercentage: 26 },
  { gender: 'Female', viewerPercentage: 26 },
  { gender: 'Others', viewerPercentage: 13 },
];

export const GENDER_CHART_COLORS = ['#4C6EF5', '#F06292', '#7C4DFF'];

export const GROWTH_CHART_COLORS = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FFC300',
];

export const formatYAxisTick = (tick: number): string => {
  if (tick >= 1000) {
    return `${(tick / 1000).toFixed(0)}k`;
  }
  return `${tick}`;
};

export const sidebarData = [
  {
    name: 'Home',
    icon: [d_applogo1x, d_applogo2x, d_applogo3x],
    navigateTo: '/home',
    heading: 'Welcome Back 👋',
    subHeading: 'Here’s what’s happening on your social media accounts.',
  },
  {
    name: 'Dashboard',
    icon: [d_dashboard1x, d_dashboard2x, d_dashboard3x],
    navigateTo: '',
    heading: 'Welcome Back 👋',
    subHeading: 'Here’s what’s happening on your social media accounts.',
  },
  {
    name: 'Link',
    icon: [d_link1x, d_link2x, d_link3x],
    navigateTo: '',
    heading: 'Link Platform',
    subHeading: 'Here you can link your social platform to Kitor.ai',
  },
  {
    name: 'Chat',
    icon: [d_chat1x, d_chat2x, d_chat3x],
    navigateTo: '',
    heading: 'Chat',
    subHeading: '',
  },
  {
    name: 'Finance',
    icon: [d_financial1x, d_financial2x, d_financial3x],
    navigateTo: '',
    heading: 'Finance',
    subHeading: '',
  },
  {
    name: 'Facebook',
    icon: [d_facebook1x, d_facebook2x, d_facebook3x],
    navigateTo: '',
    heading: 'Facebook Analytics',
    subHeading: '',
  },
  {
    name: 'Instagram',
    icon: [d_instagram1x, d_instagram2x, d_instagram3x],
    navigateTo: '',
    heading: 'Instagram Analytics',
    subHeading: '',
  },
  {
    name: 'TikTok',
    icon: [d_tiktok1x, d_tiktok2x, d_tiktok3x],
    navigateTo: '',
    heading: 'TikTok Analytics',
    subHeading: '',
  },
  {
    name: 'X',
    icon: [d_x1x, d_x2x, d_x3x],
    navigateTo: '',
    heading: 'X Analytics',
    subHeading: '',
  },
  {
    name: 'Twitch',
    icon: [d_twitch1x, d_twitch2x, d_twitch3x],
    navigateTo: '',
    heading: 'Twitch Analytics',
    subHeading: '',
  },
  {
    name: 'Youtube',
    icon: [d_youtube1x, d_youtube2x, d_youtube3x],
    navigateTo: '',
    heading: 'Youtube Analytics',
    subHeading: '',
  },
];

export const INITIAL_PROFILE_STATE = {
  fullName: '',
  username: '',
  gender: '',
  country: '',
  language: '',
  state: '',
  isValid: false,
  fullNameError: false,
  usernameError: false,
  genderError: false,
  countryError: false,
  languageError: false,
  stateError: false,
};

export const FINANCE_TABS = {
  SUMMARY: 'Summary',
  DEPOSIT: 'Deposit',
  WITHDRAW: 'Withdraw',
};

export const ACCESS_STATUS = {
  CONFIRM_SIGN_UP: 'CONFIRM_SIGN_UP',
  DONE: 'DONE',
};

export const formatViews = (views: string | number): string => {
  const numericViews = typeof views === 'string' ? parseFloat(views) : views;

  if (isNaN(numericViews)) {
    return '0';
  }

  if (numericViews < 1_000) {
    return numericViews.toString();
  } else if (numericViews < 1_000_000) {
    return (numericViews / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else if (numericViews < 1_000_000_000) {
    return (numericViews / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else {
    return (numericViews / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
};

type DecodedToken = {
  exp: number;
  [key: string]: any;
};

export const isTokenExpired = (expiryDate: number): boolean => {
  return Date.now() >= expiryDate;
};
