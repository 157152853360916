import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import {
  profile,
  verification1x,
  verification2x,
  verification3x,
} from 'assets';
import { SocialCount } from 'components';
import { useNavigate } from 'react-router-dom';

export const ProfileCard: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleCreatorProfile = () => navigate('/creator-profile');

  return (
    <Box className={classes.mainContainer} onClick={handleCreatorProfile}>
      <img src={profile} alt="Descriptive text" style={{ height: 180 }} />
      <Box className={classes.headerContainer}>
        <Typography className={classes.nameText}>Andrew Greenald</Typography>
        <img
          src={verification1x}
          srcSet={`${verification1x} 1x, ${verification2x} 2x, ${verification3x} 3x`}
          alt="Descriptive text"
        />
      </Box>
      <SocialCount />
      <Typography className={classes.bioText}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
      </Typography>
    </Box>
  );
};
