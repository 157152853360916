import { Colors } from 'global-styles';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { TooltipProps } from 'recharts';
import { useInsightsChartStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { BaseButton } from 'components/base-button';
import { formatYAxisTick } from 'utils';
import { isEmpty } from 'lodash';
import { EmptyContainer } from './empty-container';

const data = [
  { name: 'Jan', Views: 40000, Followers: 24000 },
  { name: 'Feb', Views: 30000, Followers: 13980 },
  { name: 'Mar', Views: 20000, Followers: 98000 },
  { name: 'Apr', Views: 27800, Followers: 39080 },
  { name: 'May', Views: 18900, Followers: 48000 },
  { name: 'Jun', Views: 23900, Followers: 38000 },
  { name: 'Jul', Views: 34900, Followers: 43000 },
  { name: 'Aug', Views: 34900, Followers: 43000 },
  { name: 'Sep', Views: 34900, Followers: 43000 },
  { name: 'Oct', Views: 34900, Followers: 43000 },
  { name: 'Nov', Views: 34900, Followers: 43000 },
  { name: 'Dec', Views: 34900, Followers: 43000 },
];

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
  payload,
  label,
}) => {
  if (!payload || !payload.length) return null;

  return (
    <Box
      className="custom-tooltip"
      style={{
        backgroundColor: Colors.primary,
        border: '0px',
        borderRadius: '8px',
        padding: '10px',
        color: Colors.white,
      }}>
      <Typography>{`${label}`}</Typography>
      <Typography>{`Views: ${payload[0].value}`}</Typography>
      <Typography>{`Followers: ${payload[1].value}`}</Typography>
    </Box>
  );
};

export const CustomBarChart = () => {
  const classes = useInsightsChartStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.labelRow}>
        <Typography variant="h6" textAlign={'left'}>
          Insights
        </Typography>
        <Box className={classes.labelRightBox}>
          <Box className={classes.labelView}>
            <Box
              className={classes.smallCircle}
              sx={{ background: Colors.gradientPrimary }}
            />
            <Typography variant="body2">Views</Typography>
          </Box>
          <Box className={classes.labelView}>
            <Box
              className={classes.smallCircle}
              sx={{ background: Colors.gradientSecondary }}
            />
            <Typography variant="body2">Followers</Typography>
          </Box>
          <BaseButton secondary height={35} width={100}>
            Month
          </BaseButton>
        </Box>
      </Box>
      <ResponsiveContainer width="100%" height={480}>
        {isEmpty(data) ? (
          <EmptyContainer />
        ) : (
          <BarChart data={data}>
            <CartesianGrid
              stroke={Colors.darkGrey}
              strokeDasharray="3 3"
              vertical={false}
            />
            <XAxis
              dataKey="name"
              stroke="#8884d8"
              tick={{ fill: '#8884d8' }}
              tickLine={{ stroke: '#8884d8' }}
              axisLine={{ stroke: '#8884d8' }}
            />
            <YAxis
              stroke="#8884d8"
              tick={{ fill: '#8884d8' }}
              tickLine={{ stroke: '#8884d8' }}
              axisLine={{ stroke: '#8884d8' }}
              tickFormatter={formatYAxisTick}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="Views"
              fill={Colors.gradientPrimary}
              barSize={8}
              radius={[10, 10, 0, 0]}
            />
            <Bar
              dataKey="Followers"
              fill={Colors.gradientSecondary}
              barSize={8}
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
