import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BaseButton, GlassBox } from 'components';
import VerificationInput from 'react-verification-input';
import { Colors } from 'global-styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import {
  loginUser,
  reSendVerificationCode,
  selectAccessStatus,
  selectRegisterData,
  showAlert,
  verifyEmail,
} from 'app-redux';
import { ACCESS_STATUS, ALERT_TYPES } from 'utils';
import { RootState } from 'app-redux/store';
import { useVerifyCodeStyles } from './styles';
import { isEmpty } from 'lodash';

export const VerifyCode = () => {
  const classes = useVerifyCodeStyles();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const registerData = useAppSelector(selectRegisterData);
  const accessStatus = useAppSelector(selectAccessStatus);

  const _handleVerifyCode = async () => {
    try {
      if (!isEmpty(registerData)) {
        const { email, password } = registerData;
        const resp = await dispatch(
          verifyEmail({ email, code: confirmationCode })
        ).unwrap();
        if (resp) {
          await dispatch(loginUser({ email, password })).unwrap();
          if (accessStatus === ACCESS_STATUS.DONE)
            navigation('/creator-dashboard');
        }
      }
    } catch (error) {
      dispatch(
        showAlert({
          isAlertVisible: true,
          alertType: ALERT_TYPES.ERROR,
          alertMessage: error,
        })
      );
    }
  };
  const _handleCodeResend = async () => {
    if (!isEmpty(registerData)) {
      try {
        const { email } = registerData;
        await dispatch(reSendVerificationCode(email)).unwrap();
      } catch (error) {
        dispatch(
          showAlert({
            isAlertVisible: true,
            alertType: ALERT_TYPES.ERROR,
            alertMessage: error,
          })
        );
      }
    }
  };

  return (
    <GlassBox className={classes.middleBox}>
      <Box className={classes.headerText}>
        <Typography className={classes.text}>OTP Verification</Typography>
        <Typography className={classes.description}>
          Write 6 digits OTP sent at {registerData?.email}
        </Typography>
      </Box>
      <Box className={classes.inputCont}>
        <VerificationInput
          autoFocus
          placeholder="-"
          length={6}
          onChange={(value) => setConfirmationCode(value)}
          classNames={{
            character: classes.character,
            container: classes.container,
            characterInactive: 'character--inactive',
            characterFilled: 'character--filled',
          }}
        />
      </Box>
      <Box className={classes.buttonRow}>
        <BaseButton
          secondary
          className={classes.resendButton}
          onClick={_handleCodeResend}>
          Resend OTP
        </BaseButton>
        <BaseButton
          className={classes.confirmButton}
          onClick={_handleVerifyCode}
          disabled={confirmationCode?.trim()?.length !== 6}
          style={{
            background:
              confirmationCode?.trim()?.length === 6
                ? `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`
                : Colors.lightGlass,
            color:
              confirmationCode?.trim()?.length === 6
                ? Colors.white
                : Colors.lightGlass,
          }}>
          Confirm
        </BaseButton>
      </Box>
    </GlassBox>
  );
};
