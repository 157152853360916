import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import alertSlice from './slices/alert-slice';
import authSlice from './slices/auth-slice';
import dashboardSlice from './slices/dashboard-slice';
import profileSlice from './slices/profile-slice';
import homeSlice from './slices/home-slice';
import youtubeSlice from './slices/youtube-slice';
import twitchSlice from './slices/twitch-slice';
import socialLinksSlice from './slices/social-links-slice';
import marketplaceSlice from './slices/marketplace-slice';

const reducers = combineReducers({
  alertSlice: alertSlice,
  authSlice: authSlice,
  dashboardSlice: dashboardSlice,
  profileSlice: profileSlice,
  homeSlice: homeSlice,
  youtubeSlice: youtubeSlice,
  twitchSlice: twitchSlice,
  socialLinksSlice: socialLinksSlice,
  marketplaceSlice: marketplaceSlice,
});

export const rootReducer = (state, action) => {
  if (action.type === 'logout/LOGOUT') {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authSlice', 'youtubeSlice', 'twitchSlice'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
