import React, { useEffect } from 'react';
import {
  ProfileBody,
  ProfileCollaborators,
  ProfileHeader,
  ProfileInsights,
  ProfileReach,
} from './components';
import { BackDrop, FullScreenBox, PageBlur } from 'components';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getProfileData,
  selectIsLoadingProfile,
  selectProfiledData,
} from 'app-redux';
import { useParams } from 'react-router-dom';

export const CreatorProfile = () => {
  const classes = useStyles();
  const profileData = useAppSelector(selectProfiledData);
  const isLoadingProfile = useAppSelector(selectIsLoadingProfile);
  const dispatch = useAppDispatch();
  const { username } = useParams();

  useEffect(() => {
    if (username) dispatch(getProfileData());
  }, [dispatch, username]);

  return (
    <FullScreenBox
      flexDirection={'column'}
      display={'flex'}
      position={'relative'}
      justifyContent={'center'}
      alignItems={'center'}>
      <ProfileHeader />
      <Box className={classes.mainContainer}>
        <ProfileBody />
        <ProfileCollaborators />
        <ProfileReach />
        <ProfileInsights />
      </Box>
      <PageBlur />
      <BackDrop open={isLoadingProfile} />
    </FullScreenBox>
  );
};
