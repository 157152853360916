import React, { FC, useState } from 'react';
import {
  CreatorSettings,
  DashboardHeader,
  DashboardHome,
  DashboardSidebar,
  LinkSocialPlatforms,
  SocialProfileDashboard,
} from './components';
import { BackDrop, FullScreenBox, PageBlur } from 'components';

import { Box } from '@mui/material';
import { sidebarData } from 'utils';
import { useStyles } from './styles';
import { DashboardFinance } from './components/dashboard-finance';
import { useAppSelector, useSocialInsights } from 'hooks';
import { RootState } from 'app-redux/store';
import {
  selectLoadingSocialMeta,
  selectTwitchInsights,
  selectTwitchLoading,
  selectYoutubeInsights,
  selectYoutubeLoading,
} from 'app-redux';
import { isEmpty } from 'lodash';

type tab = {
  name: string;
  icon: string[];
  navigateTo: string;
  heading?: string;
  subHeading?: string;
};

interface Props {}
export const CreatorDashboard: FC<Props> = () => {
  const classes = useStyles();
  const { isLogging } = useAppSelector((state: RootState) => state.authSlice);
  const [activeTab, setActiveTab] = useState<tab>(sidebarData[1]);
  const youtubeLoading = useAppSelector(selectYoutubeLoading);
  const youtubeInsights = useAppSelector(selectYoutubeInsights);
  const socialMetaLoading = useAppSelector(selectLoadingSocialMeta);

  const twitchLoading = useAppSelector(selectTwitchLoading);
  const twitchInsights = useAppSelector(selectTwitchInsights);

  return (
    <FullScreenBox flexDirection={'row'}>
      <DashboardSidebar
        sidebarData={sidebarData}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <Box className={classes.bodyView}>
        <DashboardHeader
          heading={activeTab?.heading}
          subheading={activeTab?.subHeading}
          icon={activeTab?.icon}
        />
        {activeTab.name === 'Dashboard' && <DashboardHome />}
        {activeTab.name === 'Link' && <LinkSocialPlatforms />}
        {activeTab.name === 'Chat' && <Box />}
        {activeTab.name === 'Finance' && <DashboardFinance />}
        {activeTab.name === 'Settings' && <CreatorSettings />}
        {activeTab.name === 'Facebook' && (
          <SocialProfileDashboard platformName="Facebook" />
        )}
        {activeTab.name === 'Instagram' && (
          <SocialProfileDashboard platformName="Instagram" />
        )}
        {activeTab.name === 'TikTok' && (
          <SocialProfileDashboard platformName="TikTok" />
        )}
        {activeTab.name === 'X' && <SocialProfileDashboard platformName="X" />}
        {activeTab.name === 'Twitch' && !isEmpty(twitchInsights) && (
          <SocialProfileDashboard platformName="Twitch" data={twitchInsights} />
        )}
        {activeTab.name === 'Youtube' && !isEmpty(youtubeInsights) && (
          <SocialProfileDashboard
            platformName="Youtube"
            data={youtubeInsights}
          />
        )}
      </Box>

      <PageBlur />

      <BackDrop
        open={isLogging || socialMetaLoading || twitchLoading || youtubeLoading}
      />
    </FullScreenBox>
  );
};
