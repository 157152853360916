import axios, { AxiosResponse } from 'axios';
import { BASE_URL, urlConstants } from 'utils';

export const Instance = () => {
  const AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Request interceptor to set the token
  AxiosInstance.interceptors.request.use(
    async (config) => {
      try {
        let accessToken = localStorage.getItem('accessToken');
        if (!accessToken || !isTokenValid(accessToken)) {
          const tokenData = await GetAuthToken();
          accessToken = tokenData.accessToken;
        }
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      } catch (error) {
        return Promise.reject(error);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Axios Response interceptors
  AxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.config && error.response && error.response.status === 401) {
        if (!error.config.__isRetryRequest) {
          try {
            const { accessToken } = await GetAuthToken();
            error.config.headers.Authorization = `Bearer ${accessToken}`;
            error.config.__isRetryRequest = true;
            return axios.request(error.config);
          } catch (tokenError) {
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

  return AxiosInstance;
};

const isTokenValid = (token: string): boolean => {
  if (!token) return false;

  try {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (!exp) return false;
    const currentTime = Date.now() / 1000;
    return exp > currentTime;
  } catch (error) {
    return false;
  }
};

const GetAuthToken = async () => {
  try {
    const userId = localStorage.getItem('userId');
    const resp: AxiosResponse | undefined = await axios.get(
      `${BASE_URL}${urlConstants.getUserToken}/${userId}`
    );
    const accessToken = resp?.data?.token;
    console.log('accessToken', resp?.data?.token);
    localStorage.setItem('accessToken', accessToken.toString());
    return { accessToken };
  } catch (error) {
    try {
      // If token fetching fails, remove accessToken and sign out the user
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      await axios.post(`${BASE_URL}${urlConstants.logout}`, {});
      console.log('Signed out successfully');
    } catch (signOutError) {
      console.log('Error during sign out:', signOutError);
    }
  }
};
