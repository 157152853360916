import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';
import { apiService } from 'services';
import { urlConstants } from 'utils';

const initialState = {
  socialMeta: null,
  loadingSocialMeta: false,
};

export const getSocialMeta = createAsyncThunk(
  'socialLinksSlice/getSocialMeta',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(urlConstants.socialMeta);
      return resp?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const socialLinksSlice = createSlice({
  name: 'socialLinksSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSocialMeta.pending, (state) => {
        state.loadingSocialMeta = true;
      })
      .addCase(getSocialMeta.fulfilled, (state, action) => {
        state.socialMeta = action.payload;
        state.loadingSocialMeta = false;
      })
      .addCase(getSocialMeta.rejected, (state) => {
        state.loadingSocialMeta = false;
      });
  },
});

const selectSocialState = (state: RootState) => state.socialLinksSlice;

const selectSocialMeta = createSelector(
  selectSocialState,
  (state) => state.socialMeta
);
const selectLoadingSocialMeta = createSelector(
  selectSocialState,
  (state) => state.loadingSocialMeta
);
export { selectSocialMeta, selectLoadingSocialMeta };
export default socialLinksSlice.reducer;
