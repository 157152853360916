import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useInsightsChartStyles } from './styles';
import { Colors } from 'global-styles';
import { formatViews, formatYAxisTick, GROWTH_CHART_COLORS } from 'utils';
import { isEmpty } from 'lodash';
import { EmptyContainer } from './empty-container';
import { Circle } from '@mui/icons-material';
import dayjs from 'dayjs';

interface ChartData {
  month: string;
  likes: number;
  views: number;
  comments: number;
  shares?: number;
  dislikes?: number;
}

const dummydata: ChartData[] = [
  {
    month: 'Jan',
    likes: 4000,
    views: 2400,
    comments: 2400,
  },
  {
    month: 'Feb',
    likes: 3000,
    views: 1398,
    comments: 2210,
  },
  {
    month: 'Mar',
    likes: 20000,
    views: 4000,
    comments: 4000,
  },
  {
    month: 'Apr',
    likes: 2780,
    views: 3908,
    comments: 2000,
  },
  { month: 'May', likes: 1890, views: 4800, comments: 2181 },
  { month: 'Jun', likes: 2390, views: 3800, comments: 2500 },
  { month: 'Jul', likes: 3490, views: 4300, comments: 2100 },
  { month: 'Aug', likes: 3490, views: 4300, comments: 2100 },
  { month: 'Sep', likes: 3490, views: 4300, comments: 2100 },
  { month: 'Oct', likes: 3490, views: 4300, comments: 2100 },
  { month: 'Nov', likes: 3490, views: 4300, comments: 2100 },
  { month: 'Dec', likes: 3490, views: 4300, comments: 2100 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        style={{
          backgroundColor: Colors.primary,
          border: '0px',
          borderRadius: '8px',
          padding: '10px',
          color: Colors.white,
        }}>
        <Typography variant="h6">{`${label}`}</Typography>
        <Typography textAlign={'left'}>
          <Circle
            style={{
              color: GROWTH_CHART_COLORS[0],
              fontSize: '10px',
              alignSelf: 'center',
              marginRight: 10,
            }}
          />
          {`Likes: ${formatViews(payload[0]?.value) || 0}`}
        </Typography>
        <Typography textAlign={'left'}>
          <Circle
            style={{
              color: GROWTH_CHART_COLORS[1],
              fontSize: '10px',
              alignSelf: 'center',
              marginRight: 10,
            }}
          />
          {`Views: ${formatViews(payload[1]?.value) || 0}`}
        </Typography>
        <Typography textAlign={'left'}>
          <Circle
            style={{
              color: GROWTH_CHART_COLORS[2],
              fontSize: '10px',
              alignSelf: 'center',
              marginRight: 10,
            }}
          />
          {`Comments: ${formatViews(payload[2]?.value) || 0}`}
        </Typography>
        <Typography textAlign={'left'}>
          <Circle
            style={{
              color: GROWTH_CHART_COLORS[3],
              fontSize: '10px',
              alignSelf: 'center',
              marginRight: 10,
            }}
          />
          {`Shares: ${formatViews(payload[3]?.value) || 0}`}
        </Typography>
        <Typography textAlign={'left'}>
          <Circle
            style={{
              color: GROWTH_CHART_COLORS[4],
              fontSize: '10px',
              alignSelf: 'center',
              marginRight: 10,
            }}
          />
          {`Dislikes: ${formatViews(payload[4]?.value) || 0}`}
        </Typography>
      </Box>
    );
  }

  return null;
};

interface Props {
  data?: any;
}
export const GrowthChart: React.FC<Props> = ({ data = dummydata }) => {
  const classes = useInsightsChartStyles();

  const formattedData = data.map((item) => ({
    ...item,
    month: dayjs(item?.month, ['MMM', 'YYYY-MM']).format('MMMM YYYY'),
  }));
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.labelRow}>
        <Typography variant="h6" className={classes.ageHeading}>
          Profile Growth and Discovery
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        {isEmpty(data) ? (
          <EmptyContainer />
        ) : (
          <LineChart
            data={formattedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke={Colors.darkGrey}
            />
            <XAxis dataKey="month" tick={{ fill: '#8884d8' }} />
            <YAxis tick={{ fill: '#8884d8' }} tickFormatter={formatYAxisTick} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="likes"
              stroke={GROWTH_CHART_COLORS[0]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="views"
              stroke={GROWTH_CHART_COLORS[1]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="comments"
              stroke={GROWTH_CHART_COLORS[2]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="shares"
              stroke={GROWTH_CHART_COLORS[3]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="dislikes"
              stroke={GROWTH_CHART_COLORS[4]}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
