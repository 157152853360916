import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'global-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    zIndex: 1,
    background: Colors.glass,
    padding: '25px !important',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  textStyle: {
    color: `${Colors.white} !important`,
    marginBottom: 10,
  },
  labelTextStyle: {
    color: `${Colors.white} !important`,
    marginBottom: 10,
    fontSize: '14px',
  },
  radioStyle: {
    color: Colors.white,
    '&.Mui-checked': {
      color: Colors.white,
    },
  },
  rightContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  createBtn: {
    background: `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`,
    marginTop: 10,
  },
  appNameTextView: {
    display: 'flex',
    position: 'absolute',
    top: 20,
    left: 30,
  },
}));
