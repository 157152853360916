import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { authInputType, userOnboardingType } from 'types';

import { AxiosResponse } from 'axios';
import { ACCESS_STATUS, urlConstants } from 'utils';
import { apiService } from 'services';
import { RootState } from 'app-redux/store';

const initialState = {
  isLogging: false,
  isLogin: false,
  accessStatus: null,
  registerData: null,
  user: null,
  error: null,
  token: null,
};

export const loginUser = createAsyncThunk(
  'authSlice/loginUser',
  async (credentials: authInputType, thunkAPI) => {
    try {
      const signInResponse: AxiosResponse | undefined =
        await apiService.postResource(urlConstants.login, credentials);
      if (signInResponse?.data) {
        localStorage.setItem('userId', signInResponse?.data?.user?._id);
        localStorage.setItem('accessToken', signInResponse?.data?.token);
        return signInResponse?.data;
      }
    } catch (error) {
      if (
        error.message ===
        'Your account is not verified. Please verify your email.'
      ) {
        thunkAPI.dispatch(setRegisterData(credentials));
        thunkAPI.dispatch(reSendVerificationCode(credentials.email));
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  'authSlice/registerUser',
  async (input: authInputType, thunkAPI) => {
    try {
      const { email, fullName } = input;
      const resp: AxiosResponse | undefined = await apiService.postResource(
        urlConstants.register,
        input
      );
      const initUser = {
        fullName: fullName,
        email: email,
      };
      return { initUser };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUser = createAsyncThunk(
  'authSlice/getUser',
  async (_, thunkAPI) => {
    try {
      const resp: AxiosResponse | undefined = await apiService.getResource(
        urlConstants.getUser
      );
      return resp.data.User;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'authSlice/verifyEmail',
  async (credentials: authInputType, thunkAPI) => {
    try {
      const resp: AxiosResponse | undefined = await apiService.postResource(
        urlConstants.verify,
        credentials
      );
      return resp.data.User;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const reSendVerificationCode = createAsyncThunk(
  'authSlice/reSendVerificationCode',
  async (email: string, thunkAPI) => {
    try {
      await apiService.postResource(urlConstants.resend, { email });
      return;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'authSlice/logoutUser',
  async (_, thunkAPI) => {
    try {
      await apiService.postResource(urlConstants.logout, {});
      localStorage.removeItem('userId');
      localStorage.removeItem('accessToken');
      thunkAPI.dispatch({ type: 'logout/LOGOUT' });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logoutUnAuthenticatedUser = createAsyncThunk(
  'authSlice/logoutUnAuthenticatedUser',
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem('userId');
      localStorage.removeItem('accessToken');
      thunkAPI.dispatch({ type: 'logout/LOGOUT' });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const userOnBoarding = createAsyncThunk(
  'authSlice/userOnBoarding',
  async (input: userOnboardingType, thunkAPI) => {
    try {
      const resp: AxiosResponse | undefined = await apiService.putResource(
        urlConstants.updateUser,
        input
      );
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setRegisterData: (state, action) => {
      state.registerData = action.payload;
      state.accessStatus = ACCESS_STATUS.CONFIRM_SIGN_UP;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLogging = true;
        state.registerData = null;
      })
      .addCase(loginUser.fulfilled, (state, action: any) => {
        const { payload } = action;
        state.isLogging = false;
        state.isLogin = true;
        state.user = payload.user;
        state.accessStatus = ACCESS_STATUS.DONE;
        state.token = payload.token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLogging = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLogging = true;
        state.registerData = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLogging = false;
        state.accessStatus = ACCESS_STATUS.CONFIRM_SIGN_UP;
        state.registerData = action.payload.initUser;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
        state.accessStatus = null;
        state.registerData = null;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.isLogging = false;
        state.accessStatus = ACCESS_STATUS.DONE;
        state.isLogin = true;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(reSendVerificationCode.pending, (state) => {
        state.isLogging = true;
      })
      .addCase(reSendVerificationCode.fulfilled, (state) => {
        state.isLogging = false;
      })
      .addCase(reSendVerificationCode.rejected, (state, action) => {
        state.isLogging = false;
      })
      .addCase(userOnBoarding.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(userOnBoarding.fulfilled, (state, action) => {
        state.isLogging = false;
        state.user = action.payload?.user;
      })
      .addCase(userOnBoarding.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.isLogging = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLogging = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLogging = false;
        state.error = action.payload;
      });
  },
});
export const { setRegisterData } = authSlice.actions;

const selectAuthState = (state: RootState) => state.authSlice;

const selectIsLogin = createSelector(selectAuthState, (state) => state.isLogin);

const selectIsLogging = createSelector(
  selectAuthState,
  (state) => state.isLogging
);

const selectRegisterData = createSelector(
  selectAuthState,
  (state) => state.registerData
);

const selectAccessStatus = createSelector(
  selectAuthState,
  (state) => state.accessStatus
);

export {
  selectRegisterData,
  selectAccessStatus,
  selectIsLogin,
  selectIsLogging,
};

export default authSlice.reducer;
