import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import {
  AppNameText,
  BaseButton,
  DropDown,
  FullScreenBox,
  PageBlur,
  TextInput,
} from 'components';
import { useStyles } from './styles';

export const PriceCalculator = () => {
  const [platform, setPlatform] = useState<string>('');
  const [accountURL, setAccountURL] = useState<string>('');
  const [contentType, setContentType] = useState<string>('');
  const [sizeOfBrand, setSizeOfBrand] = useState<string>('');
  const [periodOfTime, setPeriodOfTime] = useState<string>('');

  const classes = useStyles();
  return (
    <FullScreenBox justifyContent={'center'}>
      <Box className={classes.innerContainer}>
        <Typography variant="h5" className={classes.textStyle}>
          Influencer Price Calculator
        </Typography>

        <Grid container spacing={3}>
          {/* Left Side */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className={classes.labelTextStyle}>
                    Platform
                  </Typography>
                  <DropDown
                    list={['Facebook', 'Instagram', 'Twitter']}
                    placeholder={'Platform'}
                    setValue={setPlatform}
                    value={platform}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className={classes.labelTextStyle}>
                  Account URL
                </Typography>
                <TextInput
                  type="text"
                  placeholder="Account URL"
                  onChange={(e) => setAccountURL(e.target.value)}
                  value={accountURL}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className={classes.labelTextStyle}>
                    Content Type
                  </Typography>

                  <DropDown
                    list={['Reel', 'Post', 'Story']}
                    placeholder={'Content Type'}
                    setValue={setContentType}
                    value={contentType}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography className={classes.labelTextStyle}>
                    Size of Brand
                  </Typography>

                  <DropDown
                    list={['Big', 'Medium', 'Small']}
                    placeholder={'Size Of Brand'}
                    setValue={setSizeOfBrand}
                    value={sizeOfBrand}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className={classes.labelTextStyle}>
                  Will The Brand Whitelist Your Content?
                </Typography>
                <RadioGroup row>
                  <FormControlLabel
                    value="Yes"
                    control={<Radio className={classes.radioStyle} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio className={classes.radioStyle} />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className={classes.labelTextStyle}>
                  Is The Brand Requesting A Direct CTA?
                </Typography>
                <RadioGroup row>
                  <FormControlLabel
                    value="Yes"
                    control={<Radio className={classes.radioStyle} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio className={classes.radioStyle} />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography className={classes.labelTextStyle}>
                    Will The Brand Require Exclusivity, And If So, For What
                    Period Of Time?
                  </Typography>
                  <DropDown
                    list={['1 Month', '6 Months', '1 Year']}
                    placeholder={'Size Of Brand'}
                    setValue={setPeriodOfTime}
                    value={periodOfTime}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <BaseButton className={classes.createBtn}>
                  Calculate Price
                </BaseButton>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Side */}
          <Grid item xs={12} md={4}>
            <Box className={classes.rightContainer}>
              <Typography
                variant="h6"
                textAlign={'left'}
                className={classes.textStyle}>
                Price Estimate Per Post
              </Typography>
              <Typography variant="h5" className={classes.textStyle}>
                $300k - 450k
              </Typography>

              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: 2 }}>
                <Grid item>
                  <Typography sx={{ color: 'white' }}>
                    Total Followers
                  </Typography>
                  <Typography variant="h6" sx={{ color: 'white' }}>
                    70k
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: 'white' }}>Total Likes</Typography>
                  <Typography variant="h6" sx={{ color: 'white' }}>
                    70k
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.appNameTextView}>
        <AppNameText />
      </Box>
      <PageBlur />
    </FullScreenBox>
  );
};
