import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { useGenderChartStyles } from './styles';
import { GENDER_CHART_COLORS, genderData } from 'utils';
import { isEmpty } from 'lodash';
import { EmptyContainer } from './empty-container';

interface Props {
  data?: any;
}
// TODO: Remove genderData
export const GenderChart: React.FC<Props> = ({ data = genderData }) => {
  const classes = useGenderChartStyles();

  const renderCustomLabel = (props: any) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 2.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = `${(percent * 100).toFixed(0)}%`;

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {percentage}
      </text>
    );
  };
  return (
    <Box className={classes.mainContainer}>
      <Typography variant="h6" textAlign={'left'} sx={{ marginBottom: 2 }}>
        Genders
      </Typography>
      <ResponsiveContainer width="100%" height={isEmpty(data) ? 233 : 207}>
        {isEmpty(data) ? (
          <EmptyContainer />
        ) : (
          <PieChart>
            <Pie
              data={data}
              innerRadius={40}
              outerRadius={60}
              paddingAngle={5}
              dataKey="viewerPercentage"
              nameKey="gender"
              labelLine={true}
              label={renderCustomLabel}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  stroke="none"
                  fill={GENDER_CHART_COLORS[index % GENDER_CHART_COLORS.length]}
                />
              ))}
              <LabelList content={renderCustomLabel} />
            </Pie>
            <Tooltip />
          </PieChart>
        )}
      </ResponsiveContainer>
      {isEmpty(data) ? null : (
        <Box className={classes.labelRow}>
          <Box className={classes.labelView}>
            <Box
              className={classes.smallCircle}
              sx={{ background: GENDER_CHART_COLORS[0] }}
            />
            <Typography variant="body2">Male</Typography>
          </Box>
          <Box className={classes.labelView}>
            <Box
              className={classes.smallCircle}
              sx={{ background: GENDER_CHART_COLORS[1] }}
            />

            <Typography variant="body2">Female</Typography>
          </Box>
          <Box className={classes.labelView}>
            <Box
              className={classes.smallCircle}
              sx={{ background: GENDER_CHART_COLORS[2] }}
            />
            <Typography variant="body2">Others</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
