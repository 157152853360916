import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';
import { apiService } from 'services';
import { marketplaceURLs } from 'utils';

const initialState = {
  marketplaceData: null,
  loadingMarketplace: false,
  marketplaceError: null,
};

export const getMarketPlaceData = createAsyncThunk(
  'marketplaceSlice/getMarketPlaceData',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(
        marketplaceURLs.marketplace
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const marketplaceSlice = createSlice({
  name: 'marketplaceSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketPlaceData.pending, (state) => {
        state.loadingMarketplace = true;
        state.marketplaceError = null;
      })
      .addCase(getMarketPlaceData.fulfilled, (state, action) => {
        state.loadingMarketplace = false;
        state.marketplaceData = action.payload?.youtubeInsightsData;
      })
      .addCase(getMarketPlaceData.rejected, (state, action) => {
        state.loadingMarketplace = false;
        state.marketplaceError = action.payload as string;
      });
  },
});

const selectMarketPlaceState = (state: RootState) => state.marketplaceSlice;

const selectMarketplaceData = createSelector(
  selectMarketPlaceState,
  (state) => state.marketplaceData
);

const selectLoadingMarketplace = createSelector(
  selectMarketPlaceState,
  (state) => state.loadingMarketplace
);

export { selectMarketplaceData, selectLoadingMarketplace };

export default marketplaceSlice.reducer;
