import axios, { AxiosResponse } from 'axios';
import { Instance } from 'services';

const AxiosInstance = Instance();

const processFailedRequest = (e) => {
  if (e.status === 400 || e.status === 403 || e.status === 404) {
    throw new Error(
      e.data.message ||
        'We are not able to find your details. Please contact our support team.'
    );
  }
  throw new Error('Something went wrong. Please contact our support team');
};

const getResource = async <T, S extends T = undefined>(
  endpoint: string,
  options?: any
): Promise<AxiosResponse<S> | undefined> => {
  try {
    const response = await AxiosInstance.get<S>(endpoint, options);
    if (response.status === 200) {
      return response;
    }
    return processFailedRequest(response);
  } catch (e: any) {
    return processFailedRequest(e.response);
  }
};

const postResource = async <T, S extends T, Result = S>(
  endpoint: string,
  body: S
): Promise<AxiosResponse<Result> | undefined> => {
  try {
    const response = await AxiosInstance.post<S, AxiosResponse<Result>>(
      endpoint,
      body
    );
    if (response.status === 200 || response.status === 201) {
      return response;
    }
    return processFailedRequest(response);
  } catch (e: any) {
    return processFailedRequest(e.response);
  }
};

const deleteResource = async <T, S extends T>(
  endpoint: string
): Promise<AxiosResponse<S> | undefined> => {
  try {
    const response = await AxiosInstance.delete<S>(endpoint);
    if (response.status === 200) {
      return response;
    }
    return processFailedRequest(response);
  } catch (e: any) {
    return processFailedRequest(e.response);
  }
};
const putResource = async <T, S extends T, Result = S>(
  endpoint: string,
  body: S
): Promise<AxiosResponse<Result> | undefined> => {
  try {
    const response = await AxiosInstance.put<S, AxiosResponse<Result>>(
      endpoint,
      body
    );
    if (response.status === 200 || response.status === 204) {
      return response;
    }
    return processFailedRequest(response);
  } catch (e: any) {
    return processFailedRequest(e.response);
  }
};

export const apiService = {
  getResource,
  postResource,
  deleteResource,
  putResource,
};
