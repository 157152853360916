import {
  disconnectTwitch,
  disconnectYoutube,
  getSocialMeta,
  setTwitchMeta,
  setYoutubeMeta,
} from 'app-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { BASE_URL, twitchURLs, youtubeURLs } from 'utils';

export const useSocialLogins = () => {
  const dispatch = useAppDispatch();

  const handleLogin = (url: string, setMeta: Function) => {
    const messageHandler = (event: MessageEvent) => {
      // if (event.origin === BASE_URL) {
      console.log('handleLogin Response:', event);
      if (event.data) {
        dispatch(setMeta(event.data));
        dispatch(getSocialMeta());
      }
      window.removeEventListener('message', messageHandler);
      // }
    };

    window.addEventListener('message', messageHandler);
    window.open(url, '_blank', 'width=500,height=600');

    setTimeout(
      () => window.removeEventListener('message', messageHandler),
      30000
    );
  };

  const handleYoutubeLogin = () => {
    let accessToken = localStorage.getItem('accessToken');
    handleLogin(
      `${BASE_URL}${youtubeURLs.login}?token=${accessToken}`,
      setYoutubeMeta
    );
  };

  const handleTwitchLogin = () => {
    let accessToken = localStorage.getItem('accessToken');

    handleLogin(
      `${BASE_URL}${twitchURLs.login}?token=${accessToken}`,
      setTwitchMeta
    );
  };

  const handleDisconnectYoutube = () => dispatch(disconnectYoutube());
  const handleDisconnectTwitch = () => dispatch(disconnectTwitch());

  return {
    handleYoutubeLogin,
    handleTwitchLogin,
    handleDisconnectYoutube,
    handleDisconnectTwitch,
  };
};
