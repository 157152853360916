import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app-redux/store';
import { apiService } from 'services';
import { twitchURLs } from 'utils';
import { getSocialMeta } from './social-links-slice';

type initialStateType = {
  twitchLoading: boolean;
  twitchError: string | null;
  twitchMeta: any | null;
  twitchInsights: any | null;
};

type TwitchInsightsResponse = {
  twitchInsightsData: any;
};

const initialState: initialStateType = {
  twitchLoading: false,
  twitchError: null,
  twitchMeta: null,
  twitchInsights: null,
};
export const getTwitchInsights = createAsyncThunk<
  TwitchInsightsResponse,
  void,
  { state: RootState }
>('twitchSlice/getTwitchInsights', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const twitchMeta = state.twitchSlice.twitchMeta;

  try {
    const resp: any = await apiService.getResource(
      `${twitchURLs.insights}?refreshToken=${twitchMeta?.refresh_token}`
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getTwitchTokens = createAsyncThunk(
  'twitchSlice/getTwitchTokens',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(twitchURLs.getToken);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const disconnectTwitch = createAsyncThunk(
  'twitchSlice/disconnectTwitch',
  async (_, thunkAPI) => {
    try {
      const resp: any = await apiService.getResource(twitchURLs.disconnect);
      thunkAPI.dispatch(getSocialMeta());
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const twitchSlice = createSlice({
  name: 'twitchSlice',
  initialState,
  reducers: {
    setTwitchMeta: (state, action) => {
      state.twitchMeta = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTwitchInsights.pending, (state) => {
        state.twitchLoading = true;
        state.twitchError = null;
      })
      .addCase(getTwitchInsights.fulfilled, (state, action) => {
        state.twitchLoading = false;
        state.twitchInsights = action.payload?.twitchInsightsData;
      })
      .addCase(getTwitchInsights.rejected, (state, action) => {
        state.twitchLoading = false;
        state.twitchError = action.payload as string;
      })
      .addCase(getTwitchTokens.pending, (state) => {
        state.twitchLoading = true;
        state.twitchError = null;
      })
      .addCase(getTwitchTokens.fulfilled, (state, action) => {
        state.twitchLoading = false;
        state.twitchMeta = action.payload;
      })
      .addCase(getTwitchTokens.rejected, (state, action) => {
        state.twitchLoading = false;
        state.twitchError = action.payload as string;
      })
      .addCase(disconnectTwitch.pending, (state) => {
        state.twitchLoading = true;
      })
      .addCase(disconnectTwitch.fulfilled, (state, action) => {
        state.twitchLoading = false;
        state.twitchMeta = null;
        state.twitchInsights = null;
      })
      .addCase(disconnectTwitch.rejected, (state, action) => {
        state.twitchLoading = false;
        state.twitchError = action.payload as string;
      });
  },
});

export const { setTwitchMeta } = twitchSlice.actions;

const selectTwitchState = (state: RootState) => state.twitchSlice;

const selectTwitchInsights = createSelector(
  selectTwitchState,
  (state) => state.twitchInsights
);

const selectTwitchMeta = createSelector(
  selectTwitchState,
  (state) => state.twitchMeta
);

const selectTwitchLoading = createSelector(
  selectTwitchState,
  (state) => state.twitchLoading
);

export { selectTwitchInsights, selectTwitchMeta, selectTwitchLoading };

export default twitchSlice.reducer;
