import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  facebookOfficial,
  google3x,
  instagramOfficial,
  tiktokOfficial,
  twitchOfficial,
  xOfficial,
} from 'assets';
import { SocialButton, WarningModal } from 'components';
import { useStyles } from './styles';
import { useAppDispatch, useAppSelector, useSocialLogins } from 'hooks';
import { getSocialMeta, selectSocialMeta } from 'app-redux';

export const LinkSocialPlatforms = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const socialMeta = useAppSelector(selectSocialMeta);

  const {
    handleYoutubeLogin,
    handleTwitchLogin,
    handleDisconnectTwitch,
    handleDisconnectYoutube,
  } = useSocialLogins();

  useEffect(() => {
    dispatch(getSocialMeta());
  }, [dispatch]);

  return (
    <Box>
      <Box className={classes.buttonColumn}>
        <SocialButton
          heading="Continue with Facebook"
          logo={facebookOfficial}
        />
        <SocialButton
          heading="Continue with Instagram"
          logo={instagramOfficial}
        />
        <SocialButton heading="Continue with TikTok" logo={tiktokOfficial} />
        <SocialButton heading="Continue with X" logo={xOfficial} />
        {socialMeta?.twitch ? (
          <WarningModal
            handleDelete={handleDisconnectTwitch}
            headingText={'Confirm Disconnect'}
            text={'Are you sure you want to Disconnect your Twitch Account?'}
            buttonText={'Delete'}
            isSocialButton={true}
            CustomButton={SocialButton}
            customButtonProps={{
              heading: socialMeta.twitch.name,
              logo: twitchOfficial,
            }}
          />
        ) : (
          <SocialButton
            heading="Continue with Twitch"
            logo={twitchOfficial}
            onClick={handleTwitchLogin}
          />
        )}
        {socialMeta?.youtube ? (
          <WarningModal
            handleDelete={handleDisconnectYoutube}
            headingText={'Confirm Disconnect'}
            text={'Are you sure you want to Disconnect your Youtube Account?'}
            buttonText={'Delete'}
            isSocialButton={true}
            CustomButton={SocialButton}
            customButtonProps={{
              heading: socialMeta.youtube.name,
              logo: google3x,
            }}
          />
        ) : (
          <SocialButton
            heading="Continue with Google"
            logo={google3x}
            onClick={handleYoutubeLogin}
          />
        )}
      </Box>
    </Box>
  );
};
