import {
  getTwitchInsights,
  getTwitchTokens,
  getYoutubeInsights,
  getYoutubeTokens,
  selectIsLogin,
  selectTwitchMeta,
  selectYoutubeMeta,
} from 'app-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { isEmpty } from 'lodash';

export const useSocialInsights = () => {
  const dispatch = useAppDispatch();
  const youtubeMeta = useAppSelector(selectYoutubeMeta);
  const twitchMeta = useAppSelector(selectTwitchMeta);

  const isLogin = useAppSelector(selectIsLogin);

  const handleYoutubeInsights = async () => {
    if (isLogin) {
      //TODO: || isTokenExpired(youtubeMeta?.expiry_date) add it later
      if (isEmpty(youtubeMeta)) {
        await dispatch(getYoutubeTokens());
        dispatch(getYoutubeInsights());
      } else {
        dispatch(getYoutubeInsights());
      }
    }
  };

  const handleTwitchInsights = async () => {
    if (isLogin) {
      if (isEmpty(twitchMeta)) {
        await dispatch(getTwitchTokens());
        dispatch(getTwitchInsights());
      } else {
        dispatch(getTwitchInsights());
      }
    }
  };

  return {
    handleYoutubeInsights,
    handleTwitchInsights,
  };
};
