import React, { useState, FC } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { Colors } from 'global-styles';
import { Delete } from '@mui/icons-material';
import { BaseButton } from 'components/base-button';

interface Props {
  handleDelete: () => void;
  text: string;
  iconButton?: boolean;
  headingText: string;
  buttonText: string;
  icon?: React.ReactNode;
  buttonSize?: string;
  primaryButtonText?: string;
  buttonHeight?: number;
  buttonWidth?: number;
  isSocialButton?: boolean;
  CustomButton?: React.ComponentType<any>;
  customButtonProps?: Record<string, any>;
}
export const WarningModal: FC<Props> = ({
  handleDelete,
  text,
  headingText,
  buttonText,
  primaryButtonText = 'Discard',
  buttonHeight = 40,
  buttonWidth = 155,
  CustomButton,
  isSocialButton,
  customButtonProps,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const _handleDelete = () => {
    handleClose();
    handleDelete();
  };
  return (
    <div>
      {isSocialButton ? (
        <CustomButton {...customButtonProps} onClick={handleOpen} />
      ) : (
        <BaseButton
          secondary
          buttonColor={Colors.tomatoLite}
          width={buttonWidth}
          height={buttonHeight}
          startIcon={<Delete />}
          onClick={handleOpen}>
          {primaryButtonText}
        </BaseButton>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 3, color: Colors.black }}>
            {headingText}
          </Typography>
          <Typography variant="body2" sx={{ mb: 4, color: Colors.black }}>
            {text}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <BaseButton
              secondary
              buttonColor={Colors.primary}
              width={100}
              height={38}
              onClick={handleClose}>
              Cancel
            </BaseButton>
            <BaseButton
              buttonColor={Colors.errorColor}
              width={100}
              height={38}
              onClick={_handleDelete}>
              {buttonText}
            </BaseButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
