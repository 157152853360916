import { Avatar, Box, Typography, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { BaseButton } from 'components';
import { RootState } from 'app-redux/store';
import { useAppSelector } from 'hooks';
import { Colors } from 'global-styles';

interface Props {
  onSaveChanges?: () => void;
  setDisplayPicture: (val: string | null) => void;
  displayPicture: string | null;
  isSaveDisabled: boolean;
}

export const OnBoardingHeader: FC<Props> = ({
  onSaveChanges,
  setDisplayPicture,
  displayPicture,
  isSaveDisabled,
}) => {
  const classes = useStyles();
  const { user } = useAppSelector((state: RootState) => state.authSlice);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDisplayPicture(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerLeftContainer}>
        <Box position="relative">
          <Avatar
            alt="avatar"
            src={displayPicture}
            sx={{ width: 100, height: 100 }}
          />
          <IconButton
            component="label"
            sx={{ position: 'absolute', bottom: 0, right: 0, left: 0, top: 0 }}>
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
          </IconButton>
        </Box>
        <Box className={classes.columnView}>
          <Typography variant="h6">{user?.fullName}</Typography>
          <Typography variant="body2" className={classes.emailText}>
            {user?.email}
          </Typography>
        </Box>
      </Box>
      <BaseButton
        onClick={onSaveChanges}
        disabled={isSaveDisabled}
        width={150}
        style={{
          background: !isSaveDisabled
            ? `linear-gradient(to right, ${Colors.gradientPrimary}, ${Colors.gradientSecondary}) no-repeat`
            : Colors.lightGlass,
          color: !isSaveDisabled ? 'white' : Colors.lightGlass,
        }}>
        Save Changes
      </BaseButton>
    </Box>
  );
};
