import { Avatar, Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { avatar } from 'assets';
import { Colors } from 'global-styles';
import { useProfileCardStyles } from './styles';
import { BaseButton } from 'components';
import { isEmpty } from 'lodash';
import { EmptyProfileCard } from './empty-Profile-card';
import { formatViews } from 'utils';

interface Props {
  data?: any;
}
export const TwitchProfileCard: FC<Props> = ({ data }) => {
  const classes = useProfileCardStyles();

  if (isEmpty(data)) return <EmptyProfileCard />;

  const _handleRedirect = () => {
    const URL = `https://www.twitch.tv/${data?.username}`;
    window.open(URL, '_blank', 'noreferrer');
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.rowOverView}>
        <Avatar
          alt="twitch_profile"
          src={data?.profileImageUrl}
          sx={{ width: 100, height: 100 }}
        />
        <BaseButton
          width={100}
          style={{ background: '#9146FF' }}
          onClick={_handleRedirect}>
          Twitch
        </BaseButton>
      </Box>
      <Box>
        <Typography variant="h6">{data?.username}</Typography>
        <Typography variant="body2" color={Colors.lightGrey}>
          @{data?.username}
        </Typography>
      </Box>
      <Typography variant="body1" color={Colors.lightGrey}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s
      </Typography>
      <Box className={classes.rowView}>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={'center'}>
            {formatViews(data?.following)}
          </Typography>
          <Typography variant="body1" textAlign={'center'}>
            Following
          </Typography>
        </Box>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={'center'}>
            {formatViews(data?.followers)}
          </Typography>
          <Typography variant="body1" textAlign={'center'}>
            Followers
          </Typography>
        </Box>
        <Box className={classes.columnView}>
          <Typography variant="h6" textAlign={'center'}>
            {formatViews(data?.views)}
          </Typography>
          <Typography variant="body1" textAlign={'center'}>
            Views
          </Typography>
        </Box>
      </Box>
      <Box className={classes.spacer} />
      <Box className={classes.overViewContainer}>
        <Typography variant="h6" textAlign={'left'}>
          Overview
        </Typography>
        <Box className={classes.rowOverView}>
          <Typography variant="body2">Current viewers</Typography>
          <Typography variant="body1">
            {formatViews(data?.currentViewers)}
          </Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography variant="body2">Average concurrent viewers</Typography>
          <Typography variant="body1">
            {' '}
            {formatViews(data?.averageConcurrentViewers)}
          </Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography variant="body2">Live hours watched</Typography>
          <Typography variant="body1">
            {formatViews(data?.liveHoursWatched)}
          </Typography>
        </Box>
        <Box className={classes.rowOverView}>
          <Typography variant="body2">Total hours watched</Typography>
          <Typography variant="body1">
            {formatViews(data?.totalHoursWatched)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
