import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface Props {
  image: string[];
  heading: string;
  count: string | number;
  duration: string | number;
  percentage: string | number;
}
export const InfoCard: React.FC<Props> = ({
  image,
  heading,
  count,
  duration,
  percentage,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <Box className={classes.headerContainer}>
          <img
            src={image[0]}
            srcSet={`${image[0]} 1x, ${image[1]} 2x, ${image[2]} 3x`}
            alt="Descriptive text"
          />
        </Box>
        <Typography className={classes.headingText}>{heading}</Typography>
      </Box>
      <Box className={classes.footerContainer}>
        <Box>
          <Typography className={classes.countText}>{count}</Typography>
          <Typography className={classes.text2}>{duration}</Typography>
        </Box>

        <Box className={classes.percentageView}>
          <Typography className={classes.text2}>{percentage || 0}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
